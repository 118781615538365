<template>
	<div id="reprintPosTicket" align="center" v-show="false">
		<table border="0" width="450">
			<tr>
				<td align="center" colspan="3">
					<img src="resources/images/logo.png" width="200" />
				</td>
			</tr>
			<tr>
				<td align="center" colspan="3">
					<div class="txt3">=========================================</div>
				</td>
			</tr>
			<tr>
				<td width="33%" align="center" class="txt2">
					<b>Ticket</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>Fecha</b>
				</td>
				<td width="33%" align="center" class="txt2">
					<b>Hora</b>
				</td>
			</tr>
			<tr>
				<td width="33%" align="center" class="txt3">#{{ ticketData.id }}</td>
				<td width="33%" align="center" class="txt3">{{ moment(ticketData.created_at).format('YYYY-MM-DD') }}</td>
				<td width="33%" align="center" class="txt3">{{ moment(ticketData.created_at).format('HH:mm:ss') }} hrs</td>
			</tr>
			<tr>
				<td align="center" colspan="3">
					<div class="txt3">=========================================</div>
				</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr v-for="(record, index) in cart" :key="index">
				<td align="left" class="txt3 p07">
					{{ record.label }}
					<br />
					<small><b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}</small>
					<span v-if="!hasGiftTicket">
						<small v-if="record.wc_discount">
							<br />
							<b>Promoción</b>: -{{ record.wc_discount }}%
						</small>
						<br />
						<div v-if="record.wc_discount">
							<span style="text-decoration: line-through">{{ numeral(getPrice(record.product.prices)).format('$0,0.00') }}</span>
							<span> | {{ record.sale_price_label }}</span>
						</div>
						<b v-else>{{ record.amount_label }}</b>
					</span>
				</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr v-if="!orderDiscount">
				<td align="right" class="txt3 p07"><b>Total:</b> {{ numeral(total).format('$0,0.00') }}</td>
			</tr>
			<tr v-else>
				<td align="right" class="txt3 p07"><b>Subtotal:</b> {{ numeral(total).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="orderDiscount">
				<td align="right" class="txt3 p07"><b>Descuento:</b> {{ numeral(orderDiscount).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="orderDiscount">
				<td align="right" class="txt3 p07"><b>Total:</b> {{ numeral(total - orderDiscount).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="totalAdvances > 0">
				<td align="right" class="txt3 p07"><b>Anticipo:</b> {{ numeral(totalAdvances).format('$0,0.00') }}</td>
			</tr>
		</table>
		<table border="0" width="450" v-if="ticketData.showPaymentMethods">
			<tr>
				<td align="center" class="txt2 pt10">
					<b>=== Formas de Pago ===</b>
				</td>
			</tr>
			<tr v-if="numeral(paymentData.payment_cash).value() > 0">
				<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(paymentData.payment_cash).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="numeral(paymentData.payment_bank_terminal).value() > 0 || numeral(paymentData.payment_billpocket).value() > 0">
				<td align="right" class="txt3 p07"><b>Tarjetas:</b> {{ numeral(numeral(paymentData.payment_bank_terminal).value() + numeral(paymentData.payment_billpocket).value()).format('$0,0.00') }}
				</td>
			</tr>
			<tr v-if="numeral(paymentData.payment_transfer).value() > 0">
				<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(paymentData.payment_transfer).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="numeral(paymentData.payment_paypal).value() > 0">
				<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(paymentData.payment_paypal).format('$0,0.00') }}</td>
			</tr>
			<tr v-if="numeral(paymentData.payment_mercadopago).value() > 0">
				<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(paymentData.payment_mercadopago).format('$0,0.00') }}</td>
			</tr>
		</table>
		<table border="0" width="450">
			<tr v-if="setAdvance" class="txt2 pt10">
				<td align="center" class="txt3 p07">
					<b>*** ESTE COBRO ES UN ANTICIPO ***</b>
				</td>
			</tr>
			<tr>
				<td align="center">
					<div class="txt3">=========================================</div>
				</td>
			</tr>
			<tr>
				<td align="center" class="txt2">
					<b>=== Cliente ===</b>
				</td>
			</tr>
			<tr>
				<td align="left" class="txt3 p07">
					<b>Nombre:</b> {{ `${client.client_name} ${client.client_surname}` }} {{ client.client_last_surname | replaceIfLogicalFalse('') }}<br />
					<b>Email:</b> {{ client.email | replaceIfLogicalFalse('- No establecido -') }}<br />
					<b>Teléfono de contacto:</b> {{ client.primary_phone }}<br />
					<div v-if="!setAdvance">
						<b>Tipo de venta:</b> {{ ticketData.saleType == 'total' ? 'Venta de contado' : 'Venta a Crédito' }}
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import Vue from 'vue'
import numeral from 'numeral'
import moment from 'moment'
import VueHtmlToPaper from 'vue-html-to-paper'
import utilities from '@/services/utilities'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css', 'resources/css/printTickets.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'ticketComponent',
	components: {},
	props: {
		ticketData: {
			type: Object,
			default: () => { },
		},
		theSalesman: {
			type: String,
			default: '',
		},
		serviceOrder: {
			type: Number,
			default: 0,
		},
		orderDiscount: {
			type: Number,
			default: 0,
		},
		cart: {
			type: Array,
			default: () => [],
		},
		paymentData: {
			type: Object,
			default: () => { },
		},
		setAdvance: {
			type: Boolean,
			default: false,
		},
		advances: {
			type: Array,
			default: () => [],
		},
		client: {
			type: Object,
			default: () => { },
		},
		hasGiftTicket: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		totalAdvances() {
			if (this.advances.length > 0) {
				let total = 0
				this.advances.forEach((element) => {
					total += element.total
				})
				return total
			}
			return 0
		},
		hasClient() {
			return !!utilities.objectValidate(this.client, 'client_name', false)
		},
		total() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += e.amount
			})
			return summ
		},
		totalProducts() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += numeral(e.quantity).value()
			})
			return numeral(summ).value()
		},
	},
	methods: {
		numeral,
		moment,
		getPrice(string) {
			if (!string) {
				return 0
			}
			return JSON.parse(string).sale_price
		},
	},
}
</script>
