<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12 text-center">
					<div class="card bg-primary border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Total venta del día</div>
								<div class="font-size-18">{{ numeral(report.total).format('$0,0.00') }}</div>
								<div v-if="report.totalAreas.atc.payment_advance">
									De los cuales, {{ numeral(report.totalAreas.atc.payment_advance).format('$0,0.00') }} fueron anticipos recibidos
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center">
					<div class="card bg-success border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Efectivo</div>
								<div class="font-size-18">{{ numeral(report.paymentData.payment_cash).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-8 text-center">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21 pointer" @click="openModal('digital')">Digital</div>
								<div class="font-size-18">
									{{
										numeral(report.paymentData.payment_bank_terminal + report.paymentData.payment_transfer + report.paymentData.payment_paypal +
											report.paymentData.payment_mercadopago).format(
												'$0,0.00',
											)
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Ventas del día" force-render>
					<div class="row">
						<div class="col-md-3">
							<b>Rango de Fecha </b>
							<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
							<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :value-format="'YYYY-MM-DD'"
											:format="'YYYY-MM-DD'" />
						</div>
						<div class="col-md-3">
							<b>Cajero</b>
							<a-select style="width: 100%" placeholder="Selecciona" show-search option-filter-prop="children" :filter-option="filterOption" v-model="filters.cashier" @change="initModule"
									  allowClear>
								<a-select-option v-for="(user, index) in usersList" :key="index" :value="user.user_id">{{ user.fullName }}</a-select-option>
							</a-select>
						</div>
						<div class="col-md-3">
							<b>Forma de pago</b>
							<a-select style="width: 100%" placeholder="Selecciona" show-search option-filter-prop="children" :filter-option="filterOption" v-model="filters.paymentMethod"
									  @change="initModule" allowClear>
								<a-select-option value="payment_cash">Efectivo</a-select-option>
								<a-select-option value="payment_bank_terminal">Terminal</a-select-option>
								<a-select-option value="payment_transfer">Transferencia</a-select-option>
								<a-select-option value="payment_paypal">PayPal</a-select-option>
								<a-select-option value="payment_mercadopago">MercadoPago</a-select-option>
							</a-select>
						</div>
						<div class="col-md-3">
							<b># de Ticket</b>
							<a-input v-model="filters.ticket" v-mask="'####'" v-on:keyup.enter="searchByTicket" allowClear @change="onResetTicket" />
						</div>
					</div>

					<a-table :columns="columns" :dataSource="ticketListUnique" rowKey="id" class="pt20">
						<div slot="total" slot-scope="record">
							{{ numeral(record.total).format('$0,0.00') }}
							<br />
							<div v-if="record.sale_type">
								<a-tag color="green" v-if="record.sale_type == 'total'">
									Contado
								</a-tag>
								<div v-if="record.sale_type == 'receivable'">
									<a-tag color="yellow">Crédito</a-tag>
									<div v-if="record.receivable_date">
										<a-tag color="purple">Pagado: {{ moment(record.receivable_date).format('DD-MM-YYYY HH:mm:ss') }}</a-tag>
									</div>
								</div>
							</div>
							<a-tag color="blue" v-if="record.is_advance"> Anticipo </a-tag>
						</div>
						<div slot="created_at" slot-scope="record">{{ moment(record.created_at).format('DD-MM-YYYY HH:mm:ss') }} hrs.</div>
						<div slot="payments" slot-scope="record" class="text-right">
							<span v-if="record.payment_bank_terminal > 0">
								<a-tag color="blue">
									{{ numeral(record.payment_bank_terminal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/credit-card.png" height="20" />
										Terminal
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_cash > 0">
								<a-tag color="green">
									{{ numeral(record.payment_cash).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/cash.png" height="20" />
										Efectivo
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_mercadopago > 0">
								<a-tag color="cyan">
									{{ numeral(record.payment_mercadopago).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/mercadopago.png" height="20" />
										MercadoPago
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_paypal > 0">
								<a-tag color="purple">
									{{ numeral(record.payment_paypal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/paypal.png" height="20" />
										PayPal
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_transfer > 0">
								<a-tag color="orange">
									{{ numeral(record.payment_transfer).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/money-transfer.png" height="20" />
										Transferencias
									</span>
								</a-tag>
							</span>
						</div>
						<div slot="action" slot-scope="record">
							<a-button class="btn btn-success" size="small" icon="printer" @click="onPrintTicket(record)" />
							<a-button class="btn btn-warning ml7" size="small" icon="paper-clip" @click="onBillSale(record.id)" v-if="record.cart && !record.billed" />
							<a-button class="btn btn-warning ml7" size="small" icon="cloud-download" @click="downloadBill(record.invoiceID)" v-if="record.invoiceID" />
							<a-button class="btn btn-info ml7" size="small" icon="dollar" @click="setPaymentDate(record.id)" v-if="record.sale_type == 'receivable' && !record.receivable_date" />
						</div>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Anticipos activos" force-render>
					<a-table :columns="advanceColumns" :dataSource="advancesList" rowKey="id" class="pt20">
						<div slot="total" slot-scope="record">{{ numeral(record.total).format('$0,0.00') }}</div>
						<div slot="created_at" slot-scope="record">{{ moment(record.created_at).format('DD-MM-YYYY HH:mm:ss') }} hrs.</div>
						<div slot="payments" slot-scope="record" class="text-right">
							<span v-if="record.payment_bank_terminal > 0">
								<a-tag color="blue">
									{{ numeral(record.payment_bank_terminal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/credit-card.png" height="20" />
										Terminal
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_cash > 0">
								<a-tag color="green">
									{{ numeral(record.payment_cash).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/cash.png" height="20" />
										Efectivo
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_mercadopago > 0">
								<a-tag color="cyan">
									{{ numeral(record.payment_mercadopago).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/mercadopago.png" height="20" />
										MercadoPago
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_paypal > 0">
								<a-tag color="purple">
									{{ numeral(record.payment_paypal).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/paypal.png" height="20" />
										PayPal
									</span>
								</a-tag>
								<br />
							</span>
							<span v-if="record.payment_transfer > 0">
								<a-tag color="orange">
									{{ numeral(record.payment_transfer).format('$0,0.00') }}
									<span style="font-size: 8px">
										<img src="resources/images/money-transfer.png" height="20" />
										Transferencias
									</span>
								</a-tag>
							</span>
						</div>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-spin>
		<ticketComponent v-if="ticketVisible" :setAdvance="ticket.setAdvance" :ticketData="ticket.ticketData" :advances="ticket.advances" :theSalesman="ticket.fullName" :cart="ticket.cart"
						 :paymentData="ticket.paymentData" :hasGiftTicket="giftTicket" :serviceOrder="ticket.serviceOrder" :orderDiscount="ticket.orderDiscount" :client="localClient" />
		<a-modal :visible="modalDetailDigitalVisible" title="Detalle de ingresos digitales" :closable="false" width="70%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('digital')"> Cerrar </a-button>
			</template>
			<div class="row">
				<div class="col-md-8 text-center">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Digital</div>
								<div class="font-size-18">
									{{
										numeral(report.paymentData.payment_bank_terminal + report.paymentData.payment_transfer + report.paymentData.payment_paypal +
											report.paymentData.payment_mercadopago).format(
												'$0,0.00',
											)
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Bancaria</div>
								<div class="font-size-18">{{ numeral(report.paymentData.payment_bank_terminal).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Bancaria - Crédito</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations.panel.credit.total).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Terminal Bancaria - Débito</div>
								<div class="font-size-18">{{ numeral(bankTerminalOperations.panel.debit.total).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Transferencia</div>
								<div class="font-size-18">{{ numeral(report.paymentData.payment_transfer).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">PayPal</div>
								<div class="font-size-18">{{ numeral(report.paymentData.payment_paypal).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 text-center">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">MercadoPago</div>
								<div class="font-size-18">{{ numeral(report.paymentData.payment_mercadopago).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
		<a-modal :visible="paymentModal" title="Pago" :closable="false" width="70%">
			<template slot="footer">
				<a-button key="back" @click="closePaymentModal" :loading="spinnerLoader"> Cerrar </a-button>
				<a-button key="submit" class="btn btn-success" @click="checkOut" :loading="spinnerLoader"> Cobrar </a-button>

				<!-- 	<a-button class="btn btn-info" @click="printTicket" v-if="ticketData.id" :loading="spinnerLoader"> Imprimir ticket </a-button>
				<a-button key="submit" class="btn btn-success" v-if="!ticketData.id" @click="checkOut" :loading="spinnerLoader"> Cobrar </a-button>
				 -->
			</template>
			<paymentComponent ref="paymentComponent" :editable="false" :visible="paymentModal" :total="theTicket.total" :priceAdjustment="theTicket.order_discount" :client="theTicket.client"
							  @closeSale="onCloseSale" />
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import numeral from 'numeral'
import ticketComponent from '@/components/ticket'
import paymentComponent from '@/components/payment'
import _ from 'lodash'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'

const dateFormat = 'YYYY-MM-DD'
moment.tz.setDefault('America/Mexico_City')

const theStruct = {
	paymentData: {
		payment_bank_terminal: 0,
		payment_cash: 0,
		payment_mercadopago: 0,
		payment_paypal: 0,
		payment_transfer: 0,
		payment_billpocket: 0,
		payment_advance: 0,
	},
	totalTickets: 0,
	tickets: 0,
	total: 0,
	profit: 0,
	totalAreas: {
		atc: {
			amount: 0,
			tickets: 0,
			payment_bank_terminal: 0,
			payment_cash: 0,
			payment_mercadopago: 0,
			payment_paypal: 0,
			payment_transfer: 0,
			payment_billpocket: 0,
			payment_advance: 0,
		},
	},
}

export default {
	name: 'posDashboardView',
	components: {
		ticketComponent,
		paymentComponent,
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('pos', ['filters', 'ticketList', 'advancesList', 'totals', 'bankTerminalOperations']),
		...mapGetters('users', ['usersList']),
		...mapGetters('user', ['user']),
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
		spinnerLoaderLabel() {
			return this.$store.state.pos.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.pos.spinnerLoader
		},
	},
	data() {
		return {
			modalDetailDigitalVisible: false,
			modalDetailBoutiqueVisible: false,
			dateFormat,
			columns: [
				{
					title: 'Ticket',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
				},
				{
					title: 'Cajero',
					dataIndex: 'fullName',
					key: 'fullName',
					align: 'center',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
				},
				{
					title: 'Métodos de pago',
					scopedSlots: { customRender: 'payments' },
					align: 'center',
				},
				{
					title: 'Fecha',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			advanceColumns: [
				{
					title: 'Ticket',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
				},
				{
					title: 'Cajero',
					dataIndex: 'fullName',
					key: 'fullName',
					align: 'center',
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
				},
				{
					title: 'Métodos de pago',
					scopedSlots: { customRender: 'payments' },
					align: 'center',
				},
				{
					title: 'Fecha',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
				},
			],
			ticket: {},
			ticketVisible: false,
			report: _.cloneDeep(theStruct),
			giftTicket: false,
			localClient: {},
			ticketListUnique: [],
			paymentModal: false,
			theTicket: {},
		}
	},
	mounted() {
		this.getData()
		if (this.usersList.length == 0) {
			this.$store.dispatch('users/GET')
		}
	},
	methods: {
		moment,
		numeral,
		onResetFilter(key) {
			this.$store.dispatch('pos/RESET_ONE_FILTER', key)
			this.initModule()
		},
		initModule(date, dateString) {
			//
			let payload = {}
			this.report = _.cloneDeep(theStruct)

			if (!!dateString && dateString.length) {
				payload = {
					startDate: moment(dateString[0]).format('YYYY-MM-DD'),
					endDate: moment(dateString[1]).format('YYYY-MM-DD'),
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.filters.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}

			payload = {
				...payload,
				cashier: this.filters.cashier,
				paymentMethod: this.filters.paymentMethod,
			}

			// console.log('payload-->', payload)
			this.$store.dispatch('pos/GET_TICKETS', payload)
		},
		getData() {
			this.initModule()
		},
		onPrintTicket(record, giftTicket = false) {
			// console.log('onPrintTicket-->', record);
			this.giftTicket = giftTicket

			this.ticket = {
				fullName: record.fullName,
				cart: utilities.objectValidate(record, 'cart', false) ? JSON.parse(record.cart) : [],
				ticketData: {
					id: record.id,
					created_at: record.created_at,
					validator: record.validator,
					showPaymentMethods: record.sale_type == 'total' || record.sale_type == 'receivable' && record.receivable_date,
				},
				paymentData: {
					payment_bank_terminal: record.payment_bank_terminal,
					payment_cash: record.payment_cash,
					payment_mercadopago: record.payment_mercadopago,
					payment_paypal: record.payment_paypal,
					payment_transfer: record.payment_transfer,
					payment_advance: record.payment_advance,
					payment_billpocket: record.payment_billpocket,
				},
				advances: typeof record.advances === 'string' ? JSON.parse(record.advances) : _.cloneDeep(record.advances) || [],
				serviceOrder: numeral(utilities.objectValidate(record, 'service_order', 0)).value(),
				orderDiscount: numeral(utilities.objectValidate(record, 'order_discount', 0)).value(),
				setAdvance: Boolean(record.is_advance),
			}

			if (utilities.objectValidate(record, 'client', false)) {
				this.localClient = {
					id: record.client,
					client_last_surname: utilities.objectValidate(record, 'client_last_surname', ''),
					client_name: record.client_name,
					client_surname: record.client_surname,
					email: utilities.objectValidate(record, 'email', ''),
					primary_phone: utilities.objectValidate(record, 'primary_phone', ''),
				}
			} else {
				this.localClient = {}
			}

			this.ticketVisible = true

			setTimeout(() => {
				this.$htmlToPaper('reprintPosTicket')
			}, 500)

			setTimeout(() => {
				this.ticketVisible = false
			}, 3000)
		},
		onBillSale(id) {
			this.$store.dispatch('pos/GET_ONE_TICKET', id)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		downloadBill(id) {
			this.$store.dispatch('pos/DOWNLOAD_INVOICE', id)
		},
		searchByTicket() {
			let payload = {
				ticket: this.filters.ticket,
			}

			this.$store.dispatch('pos/GET_TICKETS', payload)
		},
		onResetTicket(e) {
			if (e.target.value == '') {
				this.initModule()
			}
		},
		openModal(modal) {
			switch (modal) {
				case 'digital':
					this.modalDetailDigitalVisible = true
					break;
				case 'boutique':
					this.modalDetailBoutiqueVisible = true
					break;
			}
		},
		onCloseModal(modal) {
			switch (modal) {
				case 'digital':
					this.modalDetailDigitalVisible = false
					break;
				case 'boutique':
					this.modalDetailBoutiqueVisible = false
					break;
			}
		},
		setPaymentDate(ticket) {
			// console.log('setPaymentDate-->', id);
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas confirmar pago de este ticket?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					//
					let theTicketData = this.ticketListUnique.find(e => e.id == ticket)
					// console.log('theTicketData-->', theTicketData);
					this.theTicket = {
						...theTicketData,
						client: {
							id: theTicketData.client,
							client_name: theTicketData.client_name,
							client_surname: theTicketData.client_surname,
							client_last_surname: theTicketData.client_last_surname,
							email: theTicketData.email,
							primary_phone: theTicketData.primary_phone,
						},
					}
					this.paymentModal = true
				}
			})
		},
		closePaymentModal() {
			this.paymentModal = false
		},
		onCloseSale(payload) {
			payload = {
				...payload,
				cash: Number(
					numeral(this.theTicket.total).value() -
					numeral(payload.bank_terminal).value() -
					numeral(payload.transfer).value() -
					numeral(payload.paypal).value() -
					numeral(payload.mercadopago).value(),
				),
				ticket: this.theTicket.id,
			}

			this.$store.dispatch('warehouse/CHECKOUT_RECEIVABLE', payload).then((response) => {
				this.getData()
				this.closePaymentModal()
			})
		},
		checkOut() {
			this.$refs.paymentComponent.onChekOut()
		},
	},
	watch: {
		ticketList(newData) {
			this.ticketListUnique = []
			let ticketListUnique = []

			if (newData.length) {
				let report = _.cloneDeep(theStruct)

				let ticketList = _.cloneDeep(this.ticketList)
				report.totalTickets = this.ticketList.length

				ticketList.forEach((element) => {
					//
					if (element.sale_type == 'total' && !element.receivable_date || element.sale_type == 'receivable' && element.receivable_date && element.payOnDay) {
						let area = 'atc'

						report.paymentData.payment_cash += element.payment_cash
						report.paymentData.payment_bank_terminal += element.payment_bank_terminal
						report.paymentData.payment_transfer += element.payment_transfer
						report.paymentData.payment_paypal += element.payment_paypal
						report.paymentData.payment_mercadopago += element.payment_mercadopago
						report.paymentData.payment_billpocket += element.payment_billpocket
						report.paymentData.payment_advance += element.payment_advance

						let total = element.payment_cash + element.payment_bank_terminal + element.payment_transfer + element.payment_paypal + element.payment_mercadopago + element.payment_billpocket

						report.total += total

						report.totalAreas[area].amount += total
						report.totalAreas[area].tickets++
						report.totalAreas[area].payment_bank_terminal += element.payment_bank_terminal
						report.totalAreas[area].payment_cash += element.payment_cash
						report.totalAreas[area].payment_mercadopago += element.payment_mercadopago
						report.totalAreas[area].payment_paypal += element.payment_paypal
						report.totalAreas[area].payment_transfer += element.payment_transfer
						report.totalAreas[area].payment_billpocket += element.payment_billpocket
						report.totalAreas[area].payment_advance += element.payment_advance
					}
					// console.log('-->', ticketListUnique.find(e => e.id == element.id));
					if (!ticketListUnique.find(e => e.id == element.id)) {
						ticketListUnique.push(element)
					}
				})

				this.report = _.cloneDeep(report)
				ticketListUnique.sort(function (a, b) {
					if (a.id > b.id) return -1;
					if (a.id < b.id) return 1;
					return 0;
				});
				this.ticketListUnique = _.cloneDeep(ticketListUnique)
			}
		},
	},
}
</script>

<style lang="scss">
.bgGreen {
	background-color: #cff4fc;
}
</style>